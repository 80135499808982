<template>
  <AvatarRoot :class="twMerge('h-10 w-10', props.rootClass)">
    <AvatarImage v-if="props.avatarUrl" :src="props.avatarUrl" />
    <AvatarFallback
      :class="twMerge('bg-[#4D80CB] text-white text-base', props.fallbackClass)"
    >
      {{ initials }}
    </AvatarFallback>
  </AvatarRoot>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";

const props = defineProps<{
  name?: string;
  firstname?: string;
  lastname?: string;
  avatarUrl?: string | null;
  rootClass?: string;
  fallbackClass?: string;
}>();

const initials =
  props.firstname && props.lastname
    ? (props.firstname ?? "")?.charAt(0) + (props.lastname ?? "")?.charAt(0)
    : (props.name ?? "")?.charAt(0);
</script>
